import {
    SALARY_SETTINGS_UPDATE_LOADING,
    SALARY_SETTINGS_UPDATE_SUCCESS,
    SALARY_SETTINGS_UPDATE_ERROR,
    SALARY_SETTINGS_GET_SUCCESS,
    SALARY_SETTINGS_GET_ERROR,
    SALARY_SETTINGS_GET_LOADING,
    RESET_RESULT,
  } from "../types";
  
  const initialState = {
    loading: false,
    page_loading:false,
    result: [],
    error: "",
    settingUpdateMessage: "",
  };
  
  const salarySettingReducer = (state = initialState, action) => {
    switch (action.type) {
      
      case SALARY_SETTINGS_UPDATE_LOADING:
        return {
          ...state,
          loading: action.payload,
          error: "",
        };
      case SALARY_SETTINGS_UPDATE_SUCCESS:
        return {
          ...state,
          loading: false,
          settingUpdateMessage: action.payload,
        };
      case SALARY_SETTINGS_UPDATE_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case SALARY_SETTINGS_GET_LOADING:
        return {
          ...state,
          page_loading: action.payload,
        };
      case SALARY_SETTINGS_GET_SUCCESS:
        return {
          ...state,
          page_loading: false,
          result: action.payload,
        };
      case SALARY_SETTINGS_GET_ERROR:
        return {
          ...state,
          page_loading: false,
          result: [],
          error: action.payload,
        };
      case RESET_RESULT:
        return {
          ...state,
          loading: false,
          page_loading:false,
          result:[],
          error: "",
          settingUpdateMessage: "",
        };
      default:
        return state;
    }
  };
  
  export default salarySettingReducer;
  