import {
  LEAVES_FETCH_ERROR,
  LEAVES_FETCH_LOADING,
  LEAVES_FETCH_SUCCESS,
  LEAVES_DELETE_SUCCESS,
  LEAVES_DELETE_ERROR,
  LEAVES_ADD_LOADING,
  LEAVES_ADD_SUCCESS,
  LEAVES_ADD_ERROR,
  LEAVES_UPDATE_LOADING,
  LEAVES_UPDATE_SUCCESS,
  LEAVES_UPDATE_ERROR,
  LEAVES_GET_SUCCESS,
  LEAVES_GET_ERROR,
  LEAVES_GET_LOADING,
  UPCOMING_LEAVES_FETCH_SUCCESS,
  UPCOMING_LEAVES_FETCH_LOADING,
  UPCOMING_LEAVES_FETCH_ERROR,
  RESET_RESULT,
  RESET_MESSAGES,
} from "../types";

const initialState = {
  loading: false,
  page_loading: false,
  result: [],
  error: "",
  takeLeaveError: "",
  leaveDeleteMessage: "",
  leaveUpdateMessage: "",
};

const leaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEAVES_FETCH_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case LEAVES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload,
      };
    case LEAVES_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        result: [],
        error: action.payload,
      };
    case UPCOMING_LEAVES_FETCH_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case UPCOMING_LEAVES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload,
      };
    case UPCOMING_LEAVES_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        result: [],
        error: action.payload,
      };
    case LEAVES_DELETE_SUCCESS:
      return {
        ...state,
        leaveDeleteMessage: action.payload,
      };
    case LEAVES_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LEAVES_ADD_LOADING:
      return {
        ...state,
        loading: action.payload,
        takeLeaveError: "",
      };
    case LEAVES_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload,
      };
    case LEAVES_ADD_ERROR:
      return {
        ...state,
        loading: false,
        result: [],
        takeLeaveError: action.payload,
      };
    case LEAVES_UPDATE_LOADING:
      return {
        ...state,
        loading: action.payload,
        takeLeaveError: "",
      };
    case LEAVES_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        leaveUpdateMessage: action.payload,
      };
    case LEAVES_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        takeLeaveError: action.payload,
      };
    case LEAVES_GET_LOADING:
      return {
        ...state,
        page_loading: action.payload,
        loading: action.payload,
      };
    case LEAVES_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        page_loading: false,
        result: action.payload,
      };
    case LEAVES_GET_ERROR:
      return {
        ...state,
        loading: false,
        page_loading: false,
        result: [],
        error: action.payload,
      };
    case RESET_RESULT:
      return {
        ...state,
        loading: false,
        result: [],
        error: "",
        leaveDeleteMessage: "",
        leaveUpdateMessage: "",
        takeLeaveError: "",
      };
    case RESET_MESSAGES:
      return {
        ...state,
        error: "",
        leaveDeleteMessage: "",
        leaveUpdateMessage: "",
        takeLeaveError: "",
      };
    default:
      return state;
  }
};

export default leaveReducer;
