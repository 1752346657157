import userReducer from './userReducer';
import leaveReducer from './leaveReducer';
import holidayReducer from './holidayReducer';
import salarySettingReducer from './salarySettingReducer';
import salaryList from './salaryListReducer';
import { combineReducers } from "redux";

const initialState = {
    sidebarShow: 'responsive'
  }
  
  const changeState = (state = initialState, { type, ...rest }) => {
    switch (type) {
      case 'set':
        return {...state, ...rest }
      default:
        return state
    }
  }
  
const rootReducer =combineReducers({
    sidebarState:changeState,
    users:userReducer,
    leaves:leaveReducer,
    holidays:holidayReducer,
    salarySetting:salarySettingReducer,
    salaryList:salaryList
});



export default rootReducer;