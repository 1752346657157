import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import {
  USER_FETCH_ERROR,
  USER_FETCH_SUCCESS,
  USER_FETCH_LOADING,
  USER_DELETE_SUCCESS,
  USER_DELETE_ERROR,
  USER_CHANGE_STATUS_ERROR,
  USER_CHANGE_STATUS_SUCCESS,
  USER_GET_LOADING,
  USER_GET_ERROR,
  USER_GET_SUCCESS,
  USER_UPDATE_LOADING,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  USER_LOGGED_IN_LOADING,
  USER_LOGGED_IN_SUCCESS,
  USER_LOGGED_IN_ERROR,
  USER_LOGOUT_LOADING,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_ERROR,
  UPCOMING_EVENTS_FETCH_SUCCESS,
  UPCOMING_EVENTS_FETCH_LOADING,
  UPCOMING_EVENTS_FETCH_ERROR,
  USER_CHANGE_PASSWORD_LOADING,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_ERROR,
  USER_GET_VERIFICATION_CODE_LOADING,
  USER_GET_VERIFICATION_CODE_SUCCESS,
  USER_GET_VERIFICATION_CODE_ERROR,
  USER_VERIFY_CODE_LOADING,
  USER_VERIFY_CODE_SUCCESS,
  USER_VERIFY_CODE_ERROR,
  USER_UPDATE_PASSWORD_LOADING,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PASSWORD_ERROR,
} from "src/redux/types";
import {
  fetchUsers,
  deleteUser,
  changeUserStatus,
  getUser,
  updateUser,
  loggedInUser,
  logOutUser,
  UpcomingEvents,
  changeUserPassword,
  sendVerificationCode,
  verifyCode,
  updateUserPassword
} from "../api";
import { Constant } from "../lib/Constants";

function* usersFetch(action) {
  // console.log(action.payload);
  yield put({
    type: USER_FETCH_LOADING,
    payload: true,
  });
  try {
    let res = yield call(fetchUsers);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: USER_FETCH_SUCCESS,
        payload: response.result,
      });
    } else {
      yield put({
        type: USER_FETCH_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
  }
}

function* eventsUpcoming(action) {
  // console.log(action.payload);
  // console.log("upto saga");
  yield put({
    type: UPCOMING_EVENTS_FETCH_LOADING,
    payload: true,
  });
  try {
    let res = yield call(UpcomingEvents);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: UPCOMING_EVENTS_FETCH_SUCCESS,
        payload: response.result,
      });
    } else {
      yield put({
        type: UPCOMING_EVENTS_FETCH_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
  }
}

function* userDelete(action) {
  // console.log(action.payload);

  try {
    let res = yield call(deleteUser, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: USER_DELETE_SUCCESS,
        payload: response.message,
      });
    } else {
      yield put({
        type: USER_DELETE_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: USER_DELETE_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* userStatusChange(action) {
  // console.log(action.payload);

  try {
    let res = yield call(changeUserStatus, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: USER_CHANGE_STATUS_SUCCESS,
        payload: response.message,
      });
    } else {
      yield put({
        type: USER_CHANGE_STATUS_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: USER_CHANGE_STATUS_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* userGet(action) {
  // console.log(action.payload);
  yield put({
    type: USER_GET_LOADING,
    payload: true,
  });
  try {
    let res = yield call(getUser, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: USER_GET_SUCCESS,
        payload: response.result,
      });
    } else {
      yield put({
        type: USER_GET_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: USER_GET_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* userUpdate(action) {
  // console.log(action.payload);
  yield put({
    type: USER_UPDATE_LOADING,
    payload: true,
  });
  try {
    let res = yield call(updateUser, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: USER_UPDATE_SUCCESS,
        payload: response.message,
      });
    } else {
      yield put({
        type: USER_UPDATE_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: USER_UPDATE_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* userLoggedIn(action) {
  // console.log(action.payload);
  yield put({
    type: USER_LOGGED_IN_LOADING,
    payload: true,
  });
  try {
    let res = yield call(loggedInUser);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: USER_LOGGED_IN_SUCCESS,
        payload: response.result,
      });
    } else {
      yield put({
        type: USER_LOGGED_IN_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: USER_GET_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* userLogOut(action) {
  // console.log(action.payload);
  yield put({
    type: USER_LOGOUT_LOADING,
    payload: true,
  });
  try {
    let res = yield call(logOutUser);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: USER_LOGOUT_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: USER_LOGOUT_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: USER_GET_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* passwordChange(action) {
  // console.log(action.payload);
  yield put({
    type: USER_CHANGE_PASSWORD_LOADING,
    payload: true,
  });
  try {
    let res = yield call(changeUserPassword, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: USER_CHANGE_PASSWORD_SUCCESS,
        payload: response.message,
      });
    } else {
      yield put({
        type: USER_CHANGE_PASSWORD_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: USER_UPDATE_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* verificationCodeSend(action) {
  yield put({
    type: USER_GET_VERIFICATION_CODE_LOADING,
    payload: true,
  });
  try {
    let res = yield call(sendVerificationCode, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: USER_GET_VERIFICATION_CODE_SUCCESS,
        payload: response.message,
      });
    } else {
      yield put({
        type: USER_GET_VERIFICATION_CODE_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: USER_UPDATE_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* codeVerify(action) {
  yield put({
    type: USER_VERIFY_CODE_LOADING,
    payload: true,
  });
  try {
    let res = yield call(verifyCode, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: USER_VERIFY_CODE_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: USER_VERIFY_CODE_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: USER_UPDATE_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* passwordUpdate(action) {
  // console.log(action.payload);
  yield put({
    type: USER_UPDATE_PASSWORD_LOADING,
    payload: true,
  });
  try {
    let res = yield call(updateUserPassword, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: USER_UPDATE_PASSWORD_SUCCESS,
        payload: response.message,
      });
    } else {
      yield put({
        type: USER_UPDATE_PASSWORD_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: USER_UPDATE_ERROR,
    //   payload: error.toString(),
    // });
  }
}

export function* userSaga() {
  yield takeEvery("USER_FETCH_ACTION", usersFetch);
  yield takeLatest("USER_DELETE_ACTION", userDelete);
  yield takeLatest("USER_CHANGE_STATUS_ACTION", userStatusChange);
  yield takeLatest("USER_GET_ACTION", userGet);
  yield takeLatest("USER_UPDATE_ACTION", userUpdate);
  yield takeLatest("USER_LOGGED_IN_ACTION", userLoggedIn);
  yield takeLatest("USER_LOGOUT_ACTION", userLogOut);
  yield takeLatest("UPCOMING_EVENTS_FETCH_ACTION", eventsUpcoming);
  yield takeLatest("USER_CHANGE_PASSWORD_ACTION", passwordChange);
  yield takeLatest("USER_GET_VERIFICATION_CODE_ACTION", verificationCodeSend);
  yield takeLatest("USER_VERIFY_CODE_ACTION", codeVerify);
  yield takeLatest("USER_UPDATE_PASSWORD_ACTION", passwordUpdate);
}
