import {
  USER_LOGIN_LOADING,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  USER_REGISTER_LOADING,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_ERROR,
  USER_FETCH_ERROR,
  USER_FETCH_LOADING,
  USER_FETCH_SUCCESS,
  USER_DELETE_SUCCESS,
  USER_DELETE_ERROR,
  USER_CHANGE_STATUS_SUCCESS,
  USER_CHANGE_STATUS_ERROR,
  USER_UPDATE_LOADING,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  USER_GET_SUCCESS,
  USER_GET_ERROR,
  USER_GET_LOADING,
  USER_LOGGED_IN_LOADING,
  USER_LOGGED_IN_SUCCESS,
  USER_LOGGED_IN_ERROR,
  RESET_RESULT,
  USER_LOGOUT_LOADING,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_ERROR,
  UPCOMING_EVENTS_FETCH_SUCCESS,
  UPCOMING_EVENTS_FETCH_LOADING,
  UPCOMING_EVENTS_FETCH_ERROR,
  USER_CHANGE_PASSWORD_LOADING,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_ERROR,
  USER_GET_VERIFICATION_CODE_LOADING,
  USER_GET_VERIFICATION_CODE_SUCCESS,
  USER_GET_VERIFICATION_CODE_ERROR,
  USER_VERIFY_CODE_LOADING,
  USER_VERIFY_CODE_SUCCESS,
  USER_VERIFY_CODE_ERROR,
  USER_RESET_PASSWORD_EMAIL,
  USER_VERIFICATION_CODE,
  USER_UPDATE_PASSWORD_LOADING,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PASSWORD_ERROR,
  RESET_MESSAGES,
  RESET_USER_RESULT,
} from "../types";

const initialState = {
  loading: false,
  page_loading: false,
  result: [],
  loggedInUser: [],
  logOutResult: [],
  events: [],
  codeVerificationResult: [],
  resetPasswordEmail: "",
  verificationCode: "",
  error: "",
  addUpadteUserError: "",
  userDeleteMessage: "",
  userUpdateMessage: "",
  employeeUpdateMessage: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPCOMING_EVENTS_FETCH_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case UPCOMING_EVENTS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.payload,
      };
    case UPCOMING_EVENTS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        events: [],
        error: action.payload,
      };
    case USER_GET_VERIFICATION_CODE_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case USER_GET_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload,
      };
    case USER_GET_VERIFICATION_CODE_ERROR:
      return {
        ...state,
        loading: false,
        result: [],
        error: action.payload,
      };
    case USER_VERIFY_CODE_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case USER_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        codeVerificationResult: action.payload,
      };
    case USER_VERIFY_CODE_ERROR:
      return {
        ...state,
        loading: false,
        codeVerificationResult: [],
        error: action.payload,
      };
    case USER_LOGIN_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload,
      };
    case USER_LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        result: [],
        error: action.payload,
      };
    case USER_LOGGED_IN_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case USER_LOGGED_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedInUser: action.payload,
      };
    case USER_LOGGED_IN_ERROR:
      return {
        ...state,
        loading: false,
        result: [],
        error: action.payload,
      };
    case USER_REGISTER_LOADING:
      return {
        ...state,
        loading: action.payload,
        addUpadteUserError: "",
      };
    case USER_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload,
      };
    case USER_REGISTER_ERROR:
      return {
        ...state,
        loading: false,
        result: [],
        addUpadteUserError: action.payload,
      };
    case USER_FETCH_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case USER_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload,
      };
    case USER_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        result: [],
        error: action.payload,
      };
    case USER_DELETE_SUCCESS:
      return {
        ...state,
        userDeleteMessage: action.payload,
      };
    case USER_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        userUpdateMessage: action.payload,
      };
    case USER_CHANGE_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_UPDATE_LOADING:
      return {
        ...state,
        loading: action.payload,
        addUpadteUserError: "",
      };
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        employeeUpdateMessage: action.payload,
      };
    case USER_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        addUpadteUserError: action.payload,
      };
    case USER_GET_LOADING:
      return {
        ...state,
        page_loading: action.payload,
      };
    case USER_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        page_loading: false,
        result: action.payload,
      };
    case USER_GET_ERROR:
      return {
        ...state,
        loading: false,
        page_loading: false,
        result: [],
        error: action.payload,
      };
    case USER_LOGOUT_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        logOutResult: action.payload,
      };
    case USER_LOGOUT_ERROR:
      return {
        ...state,
        loading: false,
        logOutResult: [],
        error: action.payload,
      };
    case USER_CHANGE_PASSWORD_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case USER_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        userUpdateMessage: action.payload,
      };
    case USER_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_UPDATE_PASSWORD_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case USER_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        userUpdateMessage: action.payload,
      };
    case USER_UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_RESULT:
      return {
        ...state,
        loading: false,
        result: [],
        logOutResult: [],
        events: [],
        error: "",
        userDeleteMessage: "",
        userUpdateMessage: "",
        employeeUpdateMessage: "",
        resetPasswordEmail: "",
        verificationCode: "",
        codeVerificationResult: [],
        addUpadteUserError: "",
      };
    case RESET_MESSAGES:
      return {
        ...state,
        error: "",
        userDeleteMessage: "",
        userUpdateMessage: "",
        employeeUpdateMessage: "",
        addUpadteUserError: "",
      };
    case USER_RESET_PASSWORD_EMAIL:
      return {
        ...state,
        resetPasswordEmail: action.payload,
      };
    case USER_VERIFICATION_CODE:
      return {
        ...state,
        verificationCode: action.payload,
      };
    case RESET_USER_RESULT:
      return {
        ...state,
        result: [],
      };
    default:
      return state;
  }
};

export default userReducer;
