import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import {
  LEAVES_FETCH_ERROR,
  LEAVES_FETCH_SUCCESS,
  LEAVES_FETCH_LOADING,
  LEAVES_DELETE_SUCCESS,
  LEAVES_DELETE_ERROR,
  LEAVES_ADD_LOADING,
  LEAVES_ADD_SUCCESS,
  LEAVES_ADD_ERROR,
  LEAVES_UPDATE_LOADING,
  LEAVES_UPDATE_SUCCESS,
  LEAVES_UPDATE_ERROR,
  LEAVES_GET_SUCCESS,
  LEAVES_GET_ERROR,
  LEAVES_GET_LOADING,
  UPCOMING_LEAVES_FETCH_SUCCESS,
  UPCOMING_LEAVES_FETCH_LOADING,
  UPCOMING_LEAVES_FETCH_ERROR,
} from "src/redux/types";
import {
  fetchLeaves,
  deleteLeave,
  addLeaves,
  getLeave,
  updateLeave,
  UpcomingLeaves,
} from "../api";
import { Constant } from "../lib/Constants";

function* leavesFetch(action) {
  // console.log(action.payload);
  // console.log("leaves called");
  yield put({
    type: LEAVES_FETCH_LOADING,
    payload: true,
  });
  try {
    let res = yield call(fetchLeaves);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: LEAVES_FETCH_SUCCESS,
        payload: response.result,
      });
    } else {
      yield put({
        type: LEAVES_FETCH_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
  }
}

function* leavesUpcoming(action) {
  // console.log(action.payload);
  // console.log("leaves called");
  yield put({
    type: UPCOMING_LEAVES_FETCH_LOADING,
    payload: true,
  });
  try {
    let res = yield call(UpcomingLeaves);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: UPCOMING_LEAVES_FETCH_SUCCESS,
        payload: response.result,
      });
    } else {
      yield put({
        type: UPCOMING_LEAVES_FETCH_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
  }
}
function* leavesDelete(action) {
  // console.log(action.payload);

  try {
    let res = yield call(deleteLeave, action.payload);
    let response = res.response.data;
    // console.log(response.message);
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      // console.log(response.message);
      yield put({
        type: LEAVES_DELETE_SUCCESS,
        payload: response.message,
      });
    } else {
      yield put({
        type: LEAVES_DELETE_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: LEAVES_DELETE_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* leavesAdd(action) {
  // console.log(action.payload);
  yield put({
    type: LEAVES_ADD_LOADING,
    payload: true,
  });
  try {
    let res = yield call(addLeaves, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: LEAVES_ADD_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: LEAVES_ADD_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
  }
}

function* leaveGet(action) {
  // console.log(action.payload);
  yield put({
    type: LEAVES_GET_LOADING,
    payload: true,
  });
  try {
    let res = yield call(getLeave, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: LEAVES_GET_SUCCESS,
        payload: response.result,
      });
    } else {
      yield put({
        type: LEAVES_GET_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: LEAVE_GET_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* leavesUpdate(action) {
  // console.log(action.payload);
  yield put({
    type: LEAVES_UPDATE_LOADING,
    payload: true,
  });
  try {
    let res = yield call(updateLeave, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: LEAVES_UPDATE_SUCCESS,
        payload: response.message,
      });
    } else {
      yield put({
        type: LEAVES_UPDATE_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: LEAVES_UPDATE_ERROR,
    //   payload: error.toString(),
    // });
  }
}

export function* leavesSaga() {
  yield takeEvery("LEAVES_FETCH_ACTION", leavesFetch);
  yield takeLatest("LEAVES_DELETE_ACTION", leavesDelete);
  yield takeLatest("LEAVES_ADD_ACTION", leavesAdd);
  yield takeLatest("LEAVES_GET_ACTION", leaveGet);
  yield takeLatest("LEAVES_UPDATE_ACTION", leavesUpdate);
  yield takeLatest("UPCOMING_LEAVES_FETCH_ACTION", leavesUpcoming);
}
