import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  USER_LOGIN_ERROR,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_LOADING,
  USER_REGISTER_ERROR,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_LOADING,
} from "src/redux/types";
import { loginUser, registerUser } from "../api";
import { Constant } from "../lib/Constants";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* UserLogin(action) {
  // console.log(action.payload);
  yield put({
    type: USER_LOGIN_LOADING,
    payload: true,
  });
  try {
    let res = yield call(loginUser, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      localStorage.setItem("token", response.result.token);
      const token = "JWT " + localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = token;
      yield put({
        type: USER_LOGIN_SUCCESS,
        payload: response.result,
      });
    } else {
      yield put({
        type: USER_LOGIN_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {}
}

//register user
function* UserRegister(action) {
  // console.log(action.payload);
  yield put({
    type: USER_REGISTER_LOADING,
    payload: true,
  });
  try {
    let res = yield call(registerUser, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      // localStorage.setItem("token", response.result.token)
      yield put({
        type: USER_REGISTER_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: USER_REGISTER_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {}
}

export function* authSaga() {
  yield takeLatest("USER_LOGIN_ACTION", UserLogin);
  yield takeLatest("USER_REGISTER_ACTION", UserRegister);
}
