import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { icons } from "./assets/icons";

import { Provider } from "react-redux";
import store from "./store";
import axios from "axios";
import { Constant } from "src/lib/Constants";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const token = "JWT " + localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = token;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === Constant.RESPONSE_STATUS.UNAUTHORIZED ||
      error.response.status === Constant.RESPONSE_STATUS.FORBIDDEN
    ) {
      localStorage.removeItem("token");
      window.location = "/login";
    }
    return error;
  }
);

React.icons = icons;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
