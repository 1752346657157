export const USER_LOGIN_ACTION = "USER_LOGIN_ACTION";
export const USER_LOGIN_LOADING = "USER_LOGIN_LOADING";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const USER_GET_VERIFICATION_CODE_ACTION =
  "USER_GET_VERIFICATION_CODE_ACTION";
export const USER_GET_VERIFICATION_CODE_LOADING =
  "USER_GET_VERIFICATION_CODE_LOADING";
export const USER_GET_VERIFICATION_CODE_SUCCESS =
  "USER_GET_VERIFICATION_CODE_SUCCESS";
export const USER_GET_VERIFICATION_CODE_ERROR =
  "USER_GET_VERIFICATION_CODE_ERROR";
export const USER_LOGGED_IN_ACTION = "USER_LOGGED_IN_ACTION";
export const USER_LOGGED_IN_LOADING = "USER_LOGGED_IN_LOADING";
export const USER_LOGGED_IN_SUCCESS = "USER_LOGGED_IN_SUCCESS";
export const USER_LOGGED_IN_ERROR = "USER_LOGGED_IN_ERROR";
export const USER_CHANGE_PASSWORD_ACTION = "USER_CHANGE_PASSWORD_ACTION";
export const USER_CHANGE_PASSWORD_LOADING = "USER_CHANGE_PASSWORD_LOADING";
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS";
export const USER_CHANGE_PASSWORD_ERROR = "USER_CHANGE_PASSWORD_ERROR";
export const USER_REGISTER_ACTION = "USER_REGISTER_ACTION";
export const USER_REGISTER_LOADING = "USER_REGISTER_LOADING";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";
export const USER_LOGOUT_ACTION = "USER_LOGOUT_ACTION";
export const USER_LOGOUT_LOADING = "USER_LOGOUT_LOADING";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_ERROR = "USER_LOGOUT_ERROR";
export const USER_UPDATE_ACTION = "USER_UPDATE_ACTION";
export const USER_UPDATE_LOADING = "USER_UPDATE_LOADING";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_ERROR = "USER_UPDATE_ERROR";
export const USER_FETCH_ACTION = "USER_FETCH_ACTION";
export const USER_FETCH_LOADING = "USER_FETCH_LOADING";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_FETCH_ERROR = "USER_FETCH_ERROR";
export const USER_GET_ACTION = "USER_GET_ACTION";
export const USER_GET_LOADING = "USER_GET_LOADING";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_ERROR = "USER_GET_ERROR";
export const USER_DELETE_ACTION = "USER_DELETE_ACTION";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_ERROR = "USER_DELETE_ERROR";
export const USER_CHANGE_STATUS_ACTION = "USER_CHANGE_STATUS_ACTION";
export const USER_CHANGE_STATUS_SUCCESS = "USER_CHANGE_STATUS_SUCCESS";
export const USER_CHANGE_STATUS_ERROR = "USER_CHANGE_STATUS_ERROR";
export const USER_VERIFY_CODE_ACTION = "USER_VERIFY_CODE_ACTION";
export const USER_VERIFY_CODE_LOADING = "USER_VERIFY_CODE_LOADING";
export const USER_VERIFY_CODE_SUCCESS = "USER_VERIFY_CODE_SUCCESS";
export const USER_VERIFY_CODE_ERROR = "USER_VERIFY_CODE_ERROR";
export const USER_UPDATE_PASSWORD_ACTION = "USER_UPDATE_PASSWORD_ACTION";
export const USER_UPDATE_PASSWORD_LOADING = "USER_UPDATE_PASSWORD_LOADING";
export const USER_UPDATE_PASSWORD_SUCCESS = "USER_UPDATE_PASSWORD_SUCCESS";
export const USER_UPDATE_PASSWORD_ERROR = "USER_UPDATE_PASSWORD_ERROR";
export const LEAVES_FETCH_ACTION = "LEAVES_FETCH_ACTION";
export const LEAVES_FETCH_LOADING = "LEAVES_FETCH_LOADING";
export const LEAVES_FETCH_SUCCESS = "LEAVES_FETCH_SUCCESS";
export const LEAVES_FETCH_ERROR = "LEAVES_FETCH_ERROR";
export const LEAVES_DELETE_ACTION = "LEAVES_DELETE_ACTION";
export const LEAVES_DELETE_SUCCESS = "LEAVES_DELETE_SUCCESS";
export const LEAVES_DELETE_ERROR = "LEAVES_DELETE_ERROR";
export const LEAVES_ADD_LOADING = "LEAVES_ADD_LOADING";
export const LEAVES_ADD_ACTION = "LEAVES_ADD_ACTION";
export const LEAVES_ADD_SUCCESS = "LEAVES_ADD_SUCCESS";
export const LEAVES_ADD_ERROR = "LEAVES_ADD_ERROR";
export const LEAVES_GET_LOADING = "LEAVES_GET_LOADING";
export const LEAVES_GET_ACTION = "LEAVES_GET_ACTION";
export const LEAVES_GET_SUCCESS = "LEAVES_GET_SUCCESS";
export const LEAVES_GET_ERROR = "LEAVES_GET_ERROR";
export const LEAVES_UPDATE_LOADING = "LEAVES_UPDATE_LOADING";
export const LEAVES_UPDATE_ACTION = "LEAVES_UPDATE_ACTION";
export const LEAVES_UPDATE_SUCCESS = "LEAVES_UPDATE_SUCCESS";
export const LEAVES_UPDATE_ERROR = "LEAVES_UPDATE_ERROR";
export const HOLIDAYS_FETCH_ACTION = "HOLIDAYS_FETCH_ACTION";
export const HOLIDAYS_FETCH_LOADING = "HOLIDAYS_FETCH_LOADING";
export const HOLIDAYS_FETCH_SUCCESS = "HOLIDAYS_FETCH_SUCCESS";
export const HOLIDAYS_FETCH_ERROR = "HOLIDAYS_FETCH_ERROR";
export const HOLIDAYS_DELETE_ACTION = "HOLIDAYS_DELETE_ACTION";
export const HOLIDAYS_DELETE_SUCCESS = "HOLIDAYS_DELETE_SUCCESS";
export const HOLIDAYS_DELETE_ERROR = "HOLIDAYS_DELETE_ERROR";
export const HOLIDAYS_ADD_LOADING = "HOLIDAYS_ADD_LOADING";
export const HOLIDAYS_ADD_ACTION = "HOLIDAYS_ADD_ACTION";
export const HOLIDAYS_ADD_SUCCESS = "HOLIDAYS_ADD_SUCCESS";
export const HOLIDAYS_ADD_ERROR = "HOLIDAYS_ADD_ERROR";
export const HOLIDAYS_GET_LOADING = "HOLIDAYS_GET_LOADING";
export const HOLIDAYS_GET_ACTION = "HOLIDAYS_GET_ACTION";
export const HOLIDAYS_GET_SUCCESS = "HOLIDAYS_GET_SUCCESS";
export const HOLIDAYS_GET_ERROR = "HOLIDAYS_GET_ERROR";
export const HOLIDAYS_UPDATE_LOADING = "HOLIDAYS_UPDATE_LOADING";
export const HOLIDAYS_UPDATE_ACTION = "HOLIDAYS_UPDATE_ACTION";
export const HOLIDAYS_UPDATE_SUCCESS = "HOLIDAYS_UPDATE_SUCCESS";
export const HOLIDAYS_UPDATE_ERROR = "HOLIDAYS_UPDATE_ERROR";
export const SALARY_SETTINGS_GET_LOADING = "SALARY_SETTINGS_GET_LOADING";
export const SALARY_SETTINGS_GET_ACTION = "SALARY_SETTINGS_GET_ACTION";
export const SALARY_SETTINGS_GET_SUCCESS = "SALARY_SETTINGS_GET_SUCCESS";
export const SALARY_SETTINGS_GET_ERROR = "SALARY_SETTINGS_GET_ERROR";
export const SALARY_SETTINGS_UPDATE_LOADING = "SALARY_SETTINGS_UPDATE_LOADING";
export const SALARY_SETTINGS_UPDATE_ACTION = "SALARY_SETTINGS_UPDATE_ACTION";
export const SALARY_SETTINGS_UPDATE_SUCCESS = "SALARY_SETTINGS_UPDATE_SUCCESS";
export const SALARY_SETTINGS_UPDATE_ERROR = "SALARY_SETTINGS_UPDATE_ERROR";
export const APPROVED_LEAVES_GET_LOADING = "APPROVED_LEAVES_GET_LOADING";
export const APPROVED_LEAVES_GET_ACTION = "APPROVED_LEAVES_GET_ACTION";
export const APPROVED_LEAVES_GET_SUCCESS = "APPROVED_LEAVES_GET_SUCCESS";
export const APPROVED_LEAVES_GET_ERROR = "APPROVED_LEAVES_GET_ERROR";
export const AVAILABLE_LEAVES_GET_LOADING = "AVAILABLE_LEAVES_GET_LOADING";
export const AVAILABLE_LEAVES_GET_ACTION = "AVAILABLE_LEAVES_GET_ACTION";
export const AVAILABLE_LEAVES_GET_SUCCESS = "AVAILABLE_LEAVES_GET_SUCCESS";
export const AVAILABLE_LEAVES_GET_ERROR = "AVAILABLE_LEAVES_GET_ERROR";
export const CALCULATED_SALARY_ADD_ACTION = "CALCULATED_SALARY_ADD_ACTION";
export const CALCULATED_SALARY_ADD_LOADING = "CALCULATED_SALARY_ADD_LOADING";
export const CALCULATED_SALARY_ADD_SUCCESS = "CALCULATED_SALARY_ADD_SUCCESS";
export const CALCULATED_SALARY_ADD_ERROR = "CALCULATED_SALARY_ADD_ERROR";
export const CALCULATED_SALARY_GET_ACTION = "CALCULATED_SALARY_GET_ACTION";
export const CALCULATED_SALARY_GET_LOADING = "CALCULATED_SALARY_GET_LOADING";
export const CALCULATED_SALARY_GET_SUCCESS = "CALCULATED_SALARY_GET_SUCCESS";
export const CALCULATED_SALARY_GET_ERROR = "CALCULATED_SALARY_GET_ERROR";
export const CALCULATED_SALARY_DELETE_ACTION =
  "CALCULATED_SALARY_DELETE_ACTION";
export const CALCULATED_SALARY_DELETE_LOADING =
  "CALCULATED_SALARY_DELETE_LOADING";
export const CALCULATED_SALARY_DELETE_SUCCESS =
  "CALCULATED_SALARY_DELETE_SUCCESS";
export const CALCULATED_SALARY_DELETE_ERROR = "CALCULATED_SALARY_DELETE_ERROR";
export const CALCULATED_SALARY_GET_PDF_ACTION =
  "CALCULATED_SALARY_GET_PDF_ACTION";
export const CALCULATED_SALARY_GET_PDF_LOADING =
  "CALCULATED_SALARY_GET_PDF_LOADING";
export const CALCULATED_SALARY_GET_PDF_SUCCESS =
  "CALCULATED_SALARY_GET_PDF_SUCCESS";
export const CALCULATED_SALARY_GET_PDF_ERROR =
  "CALCULATED_SALARY_GET_PDF_ERROR";
export const UPCOMING_HOLIDAYS_FETCH_ACTION = "UPCOMING_HOLIDAYS_FETCH_ACTION";
export const UPCOMING_HOLIDAYS_FETCH_LOADING =
  "UPCOMING_HOLIDAYS_FETCH_LOADING";
export const UPCOMING_HOLIDAYS_FETCH_SUCCESS =
  "UPCOMING_HOLIDAYS_FETCH_SUCCESS";
export const UPCOMING_HOLIDAYS_FETCH_ERROR = "UPCOMING_HOLIDAYS_FETCH_ERROR";
export const UPCOMING_LEAVES_FETCH_ACTION = "UPCOMING_LEAVES_FETCH_ACTION";
export const UPCOMING_LEAVES_FETCH_LOADING = "UPCOMING_LEAVES_FETCH_LOADING";
export const UPCOMING_LEAVES_FETCH_SUCCESS = "UPCOMING_LEAVES_FETCH_SUCCESS";
export const UPCOMING_LEAVES_FETCH_ERROR = "UPCOMING_LEAVES_FETCH_ERROR";
export const UPCOMING_EVENTS_FETCH_ACTION = "UPCOMING_EVENTS_FETCH_ACTION";
export const UPCOMING_EVENTS_FETCH_LOADING = "UPCOMING_EVENTS_FETCH_LOADING";
export const UPCOMING_EVENTS_FETCH_SUCCESS = "UPCOMING_EVENTS_FETCH_SUCCESS";
export const UPCOMING_EVENTS_FETCH_ERROR = "UPCOMING_EVENTS_FETCH_ERROR";
export const RESET_RESULT = "RESET_RESULT";
export const RESET_MESSAGES = "RESET_MESSAGES";
export const USER_RESET_PASSWORD_EMAIL = "USER_RESET_PASSWORD_EMAIL";
export const USER_VERIFICATION_CODE = "USER_VERIFICATION_CODE";
export const RESET_USER_RESULT = "RESET_USER_RESULT";
export const RESET_PDF_RESULTS = "RESET_PDF_RESULTS";