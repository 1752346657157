import {
  AVAILABLE_LEAVES_GET_LOADING,
  AVAILABLE_LEAVES_GET_SUCCESS,
  AVAILABLE_LEAVES_GET_ERROR,
  APPROVED_LEAVES_GET_LOADING,
  APPROVED_LEAVES_GET_SUCCESS,
  APPROVED_LEAVES_GET_ERROR,
  CALCULATED_SALARY_ADD_LOADING,
  CALCULATED_SALARY_ADD_SUCCESS,
  CALCULATED_SALARY_ADD_ERROR,
  CALCULATED_SALARY_GET_LOADING,
  CALCULATED_SALARY_GET_SUCCESS,
  CALCULATED_SALARY_GET_ERROR,
  CALCULATED_SALARY_DELETE_LOADING,
  CALCULATED_SALARY_DELETE_SUCCESS,
  CALCULATED_SALARY_DELETE_ERROR,
  CALCULATED_SALARY_GET_PDF_LOADING,
  CALCULATED_SALARY_GET_PDF_SUCCESS,
  CALCULATED_SALARY_GET_PDF_ERROR,
  RESET_MESSAGES,
  RESET_RESULT,
  RESET_PDF_RESULTS,
} from "../types";

const initialState = {
  loading: false,
  page_loading: false,
  addLoading: false,
  leaveResult: [],
  availableLeaveResult: [],
  result: [],
  pdfResult: "",
  error: "",
  addCalculatedSalaryError: "",
  salaryDeletedMessage: "",
};

const salaryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case AVAILABLE_LEAVES_GET_LOADING:
      return {
        ...state,
        page_loading: action.payload,
        error: "",
      };
    case AVAILABLE_LEAVES_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        availableLeaveResult: action.payload,
      };
    case AVAILABLE_LEAVES_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case APPROVED_LEAVES_GET_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case APPROVED_LEAVES_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        leaveResult: action.payload,
      };
    case APPROVED_LEAVES_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CALCULATED_SALARY_ADD_LOADING:
      return {
        ...state,
        addLoading: action.payload,
        addCalculatedSalaryError: "",
      };
    case CALCULATED_SALARY_ADD_SUCCESS:
      return {
        ...state,
        addLoading: false,
        result: action.payload,
      };
    case CALCULATED_SALARY_ADD_ERROR:
      return {
        ...state,
        addLoading: false,
        addCalculatedSalaryError: action.payload,
      };
    case CALCULATED_SALARY_GET_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case CALCULATED_SALARY_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload,
      };
    case CALCULATED_SALARY_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CALCULATED_SALARY_DELETE_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case CALCULATED_SALARY_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        salaryDeletedMessage: action.payload,
      };
    case CALCULATED_SALARY_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CALCULATED_SALARY_GET_PDF_LOADING:
      return {
        ...state,
        page_loading: action.payload,
      };
    case CALCULATED_SALARY_GET_PDF_SUCCESS:
      return {
        ...state,
        pdfResult: action.payload,
        page_loading: false,
      };
    case CALCULATED_SALARY_GET_PDF_ERROR:
      return {
        ...state,
        page_loading: false,
        error: action.payload,
      };
    case RESET_RESULT:
      return {
        ...state,
        leaveResult: [],
        availableLeaveResult: [],
        result: [],
        pdfResult: "",
        error: "",
        salaryDeletedMessage: "",
        addCalculatedSalaryError: "",
      };
    case RESET_MESSAGES:
      return {
        ...state,
        error: "",
        salaryDeletedMessage: "",
        addCalculatedSalaryError: "",
      };
    case RESET_PDF_RESULTS:
      return {
        ...state,
        pdfResult: "",
      };
    default:
      return state;
  }
};

export default salaryListReducer;
