import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./css/toastify.css";
import "./css/main.css";
import { getLoggedInUser } from "./redux/actions/userAction";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/auth/Login"));
const ForgotPassword = React.lazy(() => import("./views/auth/ForgotPassword"));
const VerifyCode = React.lazy(() => import("./views/auth/VerifyCode"));
const UpdatePassword = React.lazy(() => import("./views/auth/UpdatePassword"));
// const Register = React.lazy(() => import("./views/pages/register/Register"));
// const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
// const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

function App() {
  const loggedInUser = useSelector((state) => state.users.loggedInUser);
  // console.log(loggedInUser);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      dispatch(getLoggedInUser());
      // console.log(loggedInUser);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <BrowserRouter>
      <ToastContainer />
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/forgot-password"
            name="Forgot Password"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            exact
            path="/verify-code"
            name="Verify Code"
            render={(props) => <VerifyCode {...props} />}
          />
          <Route
            path="/reset-password"
            name="Reset Password"
            render={(props) => <UpdatePassword {...props} />}
          />
          {/*
          <Route
            exact
            path="/register"
            name="Register Page"
            render={props => <Register {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={props => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={props => <Page500 {...props} />}
          /> */}
          <Route
            path="/"
            name="Home"
            render={(props) => (
              <TheLayout loggedInUser={loggedInUser} {...props} />
            )}
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
