import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import {
  HOLIDAYS_FETCH_ERROR,
  HOLIDAYS_FETCH_LOADING,
  HOLIDAYS_FETCH_SUCCESS,
  HOLIDAYS_DELETE_SUCCESS,
  HOLIDAYS_DELETE_ERROR,
  HOLIDAYS_ADD_LOADING,
  HOLIDAYS_ADD_SUCCESS,
  HOLIDAYS_ADD_ERROR,
  HOLIDAYS_UPDATE_LOADING,
  HOLIDAYS_UPDATE_SUCCESS,
  HOLIDAYS_UPDATE_ERROR,
  HOLIDAYS_GET_SUCCESS,
  HOLIDAYS_GET_ERROR,
  HOLIDAYS_GET_LOADING,
  UPCOMING_HOLIDAYS_FETCH_SUCCESS,
  UPCOMING_HOLIDAYS_FETCH_LOADING,
  UPCOMING_HOLIDAYS_FETCH_ERROR,
} from "src/redux/types";
import {
  fetchHolidays,
  deleteHoliday,
  addHoliday,
  getHoliday,
  updateHoliday,
  UpcomingHolidays,
} from "../api";
import { Constant } from "../lib/Constants";

function* holidaysFetch(action) {
  // console.log(action.payload);
  // console.log("holidays called");
  yield put({
    type: HOLIDAYS_FETCH_LOADING,
    payload: true,
  });
  try {
    let res = yield call(fetchHolidays);
    // console.log(res);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: HOLIDAYS_FETCH_SUCCESS,
        payload: response.result,
      });
    } else {
      yield put({
        type: HOLIDAYS_FETCH_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
  }
}

function* holidaysUpcoming(action) {
  // console.log(action.payload);
  // console.log("holidays called");
  yield put({
    type: UPCOMING_HOLIDAYS_FETCH_LOADING,
    payload: true,
  });
  try {
    let res = yield call(UpcomingHolidays);
    // console.log(res);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: UPCOMING_HOLIDAYS_FETCH_SUCCESS,
        payload: response.result,
      });
    } else {
      yield put({
        type: UPCOMING_HOLIDAYS_FETCH_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
  }
}

function* holidaysDelete(action) {
  // console.log(action.payload);

  try {
    let res = yield call(deleteHoliday, action.payload);
    let response = res.response.data;
    // console.log(response.message);
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      // console.log(response.message);
      yield put({
        type: HOLIDAYS_DELETE_SUCCESS,
        payload: response.message,
      });
    } else {
      yield put({
        type: HOLIDAYS_DELETE_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: LEAVES_DELETE_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* holidaysAdd(action) {
  // console.log(action.payload);
  yield put({
    type: HOLIDAYS_ADD_LOADING,
    payload: true,
  });
  try {
    let res = yield call(addHoliday, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: HOLIDAYS_ADD_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: HOLIDAYS_ADD_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
  }
}

function* holidaysGet(action) {
  // console.log(action.payload);
  yield put({
    type: HOLIDAYS_GET_LOADING,
    payload: true,
  });
  try {
    let res = yield call(getHoliday, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: HOLIDAYS_GET_SUCCESS,
        payload: response.result,
      });
    } else {
      yield put({
        type: HOLIDAYS_GET_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: LEAVE_GET_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* holidaysUpdate(action) {
  // console.log(action.payload);
  yield put({
    type: HOLIDAYS_UPDATE_LOADING,
    payload: true,
  });
  try {
    let res = yield call(updateHoliday, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: HOLIDAYS_UPDATE_SUCCESS,
        payload: response.message,
      });
    } else {
      yield put({
        type: HOLIDAYS_UPDATE_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: LEAVES_UPDATE_ERROR,
    //   payload: error.toString(),
    // });
  }
}

export function* holidaySaga() {
  yield takeEvery("HOLIDAYS_FETCH_ACTION", holidaysFetch);
  yield takeLatest("HOLIDAYS_DELETE_ACTION", holidaysDelete);
  yield takeLatest("HOLIDAYS_ADD_ACTION", holidaysAdd);
  yield takeLatest("HOLIDAYS_GET_ACTION", holidaysGet);
  yield takeLatest("HOLIDAYS_UPDATE_ACTION", holidaysUpdate);
  yield takeLatest("UPCOMING_HOLIDAYS_FETCH_ACTION", holidaysUpcoming);
}
