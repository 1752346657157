import { call, put, takeLatest } from "redux-saga/effects";
import {
  AVAILABLE_LEAVES_GET_LOADING,
  AVAILABLE_LEAVES_GET_SUCCESS,
  AVAILABLE_LEAVES_GET_ERROR,
  APPROVED_LEAVES_GET_LOADING,
  APPROVED_LEAVES_GET_SUCCESS,
  APPROVED_LEAVES_GET_ERROR,
  CALCULATED_SALARY_ADD_LOADING,
  CALCULATED_SALARY_ADD_SUCCESS,
  CALCULATED_SALARY_ADD_ERROR,
  CALCULATED_SALARY_GET_LOADING,
  CALCULATED_SALARY_GET_SUCCESS,
  CALCULATED_SALARY_GET_ERROR,
  CALCULATED_SALARY_DELETE_SUCCESS,
  CALCULATED_SALARY_DELETE_ERROR,
  CALCULATED_SALARY_GET_PDF_LOADING,
  CALCULATED_SALARY_GET_PDF_SUCCESS,
  CALCULATED_SALARY_GET_PDF_ERROR,
} from "src/redux/types";
import {
  getApprovedSalaryList,
  getAvailableSalaryList,
  addCalculatedSalary,
  getCalculatedSalary,
  deleteCalculatedSalary,
  generateCalculatedSalaryPDF,
} from "../api";
import { Constant } from "../lib/Constants";

function* approvedLeavesGet(action) {
  // console.log(action.payload);
  yield put({
    type: APPROVED_LEAVES_GET_LOADING,
    payload: true,
  });
  try {
    let res = yield call(getApprovedSalaryList, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: APPROVED_LEAVES_GET_SUCCESS,
        payload: response.result,
      });
    } else {
      yield put({
        type: APPROVED_LEAVES_GET_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: LEAVE_GET_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* availableLeavesGet(action) {
  // console.log(action.payload);
  yield put({
    type: AVAILABLE_LEAVES_GET_LOADING,
    payload: true,
  });
  try {
    let res = yield call(getAvailableSalaryList, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: AVAILABLE_LEAVES_GET_SUCCESS,
        payload: response.result,
      });
    } else {
      yield put({
        type: AVAILABLE_LEAVES_GET_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: LEAVES_UPDATE_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* calculatedLeavesAdd(action) {
  // console.log(action.payload);
  yield put({
    type: CALCULATED_SALARY_ADD_LOADING,
    payload: true,
  });
  try {
    let res = yield call(addCalculatedSalary, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: CALCULATED_SALARY_ADD_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: CALCULATED_SALARY_ADD_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: LEAVES_UPDATE_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* calculatedLeavesGet(action) {
  // console.log(action.payload);
  yield put({
    type: CALCULATED_SALARY_GET_LOADING,
    payload: true,
  });
  try {
    let res = yield call(getCalculatedSalary);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: CALCULATED_SALARY_GET_SUCCESS,
        payload: response.result,
      });
    } else {
      yield put({
        type: CALCULATED_SALARY_GET_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: LEAVES_UPDATE_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* calculatedLeavesDelete(action) {
  // console.log(action.payload);

  try {
    let res = yield call(deleteCalculatedSalary, action.payload);
    let response = res.response.data;
    // console.log(response.message);
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      // console.log(response.message);
      yield put({
        type: CALCULATED_SALARY_DELETE_SUCCESS,
        payload: response.message,
      });
    } else {
      yield put({
        type: CALCULATED_SALARY_DELETE_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: LEAVES_DELETE_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* calculatedLeavesPDF(action) {
  // console.log(action.payload);
  yield put({
    type: CALCULATED_SALARY_GET_PDF_LOADING,
    payload: true,
  });
  try {
    let res = yield call(generateCalculatedSalaryPDF, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.ERROR) {
      yield put({
        type: CALCULATED_SALARY_GET_PDF_ERROR,
        payload: response.message,
      });
    } else {
      yield put({
        type: CALCULATED_SALARY_GET_PDF_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: LEAVES_DELETE_ERROR,
    //   payload: error.toString(),
    // });
  }
}

export function* salaryListSaga() {
  yield takeLatest("APPROVED_LEAVES_GET_ACTION", approvedLeavesGet);
  yield takeLatest("AVAILABLE_LEAVES_GET_ACTION", availableLeavesGet);
  yield takeLatest("CALCULATED_SALARY_ADD_ACTION", calculatedLeavesAdd);
  yield takeLatest("CALCULATED_SALARY_GET_ACTION", calculatedLeavesGet);
  yield takeLatest("CALCULATED_SALARY_DELETE_ACTION", calculatedLeavesDelete);
  yield takeLatest("CALCULATED_SALARY_GET_PDF_ACTION", calculatedLeavesPDF);
}
