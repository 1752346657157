import {
  USER_LOGIN_ACTION,
  RESET_MESSAGES,
  USER_GET_ACTION,
  USER_UPDATE_ACTION,
  USER_REGISTER_ACTION,
  RESET_RESULT,
  USER_FETCH_ACTION,
  USER_DELETE_ACTION,
  USER_CHANGE_STATUS_ACTION,
  USER_LOGGED_IN_ACTION,
  USER_LOGOUT_ACTION,
  UPCOMING_EVENTS_FETCH_ACTION,
  USER_CHANGE_PASSWORD_ACTION,
  USER_GET_VERIFICATION_CODE_ACTION,
  USER_RESET_PASSWORD_EMAIL,
  USER_VERIFY_CODE_ACTION,
  USER_VERIFICATION_CODE,
  USER_UPDATE_PASSWORD_ACTION,
  RESET_USER_RESULT,
} from "../types";

export const userLogin = (data) => {
  return {
    type: USER_LOGIN_ACTION,
    payload: data,
  };
};

export const changePassword = (data) => {
  return {
    type: USER_CHANGE_PASSWORD_ACTION,
    payload: data,
  };
};

export const getLoggedInUser = () => {
  return {
    type: USER_LOGGED_IN_ACTION,
  };
};

export const getUpcomingEvents = () => {
  return {
    type: UPCOMING_EVENTS_FETCH_ACTION,
  };
};

export const userLogout = () => {
  return {
    type: USER_LOGOUT_ACTION,
  };
};

export const userRegister = (data) => {
  return {
    type: USER_REGISTER_ACTION,
    payload: data,
  };
};

export const resetResult = () => {
  return {
    type: RESET_RESULT,
  };
};

export const resetMessages = () => {
  return {
    type: RESET_MESSAGES,
  };
};

export const fetchUsers = () => {
  return {
    type: USER_FETCH_ACTION,
  };
};

export const deleteUser = (data) => {
  return {
    type: USER_DELETE_ACTION,
    payload: data,
  };
};

export const changeUserStatus = (data) => {
  return {
    type: USER_CHANGE_STATUS_ACTION,
    payload: data,
  };
};

export const getUserData = (data) => {
  return {
    type: USER_GET_ACTION,
    payload: data,
  };
};
export const updateUserData = (data) => {
  return {
    type: USER_UPDATE_ACTION,
    payload: data,
  };
};

export const sendVerificationCode = (data) => {
  return {
    type: USER_GET_VERIFICATION_CODE_ACTION,
    payload: data,
  };
};

export const setResetPasswordEmail = (data) => {
  return {
    type: USER_RESET_PASSWORD_EMAIL,
    payload: data,
  };
};

export const verifyOtpCode = (data) => {
  return {
    type: USER_VERIFY_CODE_ACTION,
    payload: data,
  };
};

export const setVerificationCode = (data) => {
  return {
    type: USER_VERIFICATION_CODE,
    payload: data,
  };
};

export const updatePassword = (data) => {
  return {
    type: USER_UPDATE_PASSWORD_ACTION,
    payload: data,
  };
};

export const resetUserResult = () => {
  return {
    type: RESET_USER_RESULT,
  };
};
