import * as moment from "moment";
export const twentyYearBackDate = () => {
  let d = new Date();
  let pastTwentyYear = d.getFullYear() - 20;
  d.setFullYear(pastTwentyYear);

  let pastDate = `${d.getDate()}`;
  let pastMonth = `${d.getMonth() + 1}`;
  let pastYear = `${d.getFullYear()}`;

  let twentyYearBackDate = `${pastYear}-${
    pastMonth.length === 1 ? `0${pastMonth}` : pastMonth
  }-${pastDate.length === 1 ? `0${pastDate}` : pastDate}`;

  let twentyYearBackDateForSchema = `${parseInt(
    pastDate
  )}-${pastMonth}-${pastTwentyYear}`;
  // console.log(twentyYearBackDateForSchema);

  return { twentyYearBackDate, twentyYearBackDateForSchema };
};

export const todayAndPrevDate = () => {
  let currDate = `${new Date().getDate()}`;
  let currMonth = `${new Date().getMonth() + 1}`;
  let currYear = `${new Date().getFullYear()}`;

  let todayDate = `${currYear}-${
    currMonth.length === 1 ? `0${currMonth}` : currMonth
  }-${currDate.length === 1 ? `0${currDate}` : currDate}`;

  let prevDate = `${currYear}-${currMonth}-${currDate - 1}`;
  // console.log(todayDate);
  return { todayDate, prevDate };
};

export const currentDate = () => {
  let currDate = `${new Date().getDate()}`;
  let currMonth = `${new Date().getMonth() + 1}`;
  let currYear = `${new Date().getFullYear()}`;

  let currentDateForSchema = `${parseInt(currDate)}-${currMonth}-${currYear}`;
  // console.log(currentDateForSchema);
  return currentDateForSchema;
};

export const yearList = (lastYear) => {
  const currentYear = new Date().getFullYear();

  const years = [];

  for (let i = currentYear; i >= lastYear; i--) {
    years.push(i);
  }

  return years;
};

export const lastYear = () => {
  const lastmonthlastdate = moment(new Date(), false)
    .subtract(1, "months")
    .startOf("month")
    .format("YYYY");
  return lastmonthlastdate;
};

export const lastMonth = () => {
  const month = new Date().getMonth();
  // console.log(month);
  if (month === 0) {
    return 12;
  } else {
    return month + "";
  }
};

export const monthList = () => {
  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
};

export const checkYear = (year1, year2) => {
  if (year1 === year2) {
    return true;
  } else {
    return false;
  }
};
