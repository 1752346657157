import { call, put, takeLatest } from "redux-saga/effects";
import {
  SALARY_SETTINGS_UPDATE_LOADING,
  SALARY_SETTINGS_UPDATE_SUCCESS,
  SALARY_SETTINGS_UPDATE_ERROR,
  SALARY_SETTINGS_GET_SUCCESS,
  SALARY_SETTINGS_GET_ERROR,
  SALARY_SETTINGS_GET_LOADING,
} from "src/redux/types";
import { getSalarySettings, updateSalarySettings } from "../api";
import { Constant } from "../lib/Constants";

function* settingsGet(action) {
  // console.log(action.payload);
  yield put({
    type: SALARY_SETTINGS_GET_LOADING,
    payload: true,
  });
  try {
    let res = yield call(getSalarySettings);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: SALARY_SETTINGS_GET_SUCCESS,
        payload: response.result,
      });
    } else {
      yield put({
        type: SALARY_SETTINGS_GET_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: LEAVE_GET_ERROR,
    //   payload: error.toString(),
    // });
  }
}

function* settingsUpdate(action) {
  // console.log(action.payload);
  yield put({
    type: SALARY_SETTINGS_UPDATE_LOADING,
    payload: true,
  });
  try {
    let res = yield call(updateSalarySettings, action.payload);
    let response = res.response.data;
    if (response.status === Constant.RESPONSE_STATUS.SUCCESS) {
      yield put({
        type: SALARY_SETTINGS_UPDATE_SUCCESS,
        payload: response.message,
      });
    } else {
      yield put({
        type: SALARY_SETTINGS_UPDATE_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    // console.log(error);
    // yield put({
    //   type: LEAVES_UPDATE_ERROR,
    //   payload: error.toString(),
    // });
  }
}

export function* salarySettingSaga() {
  yield takeLatest("SALARY_SETTINGS_GET_ACTION", settingsGet);
  yield takeLatest("SALARY_SETTINGS_UPDATE_ACTION", settingsUpdate);
}
