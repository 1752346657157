import axios from "axios";

export function loginUser(data) {
  return axios
    .post("user/login", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function registerUser(data) {
  return axios
    .post("user/", data, { headers: { "Content-Type": "multipart/form-data" } })
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function fetchUsers() {
  return axios
    .get("user/")
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function deleteUser(id) {
  // console.log(id);
  return axios
    .delete("user/", { data: { userId: id } })
    .then(function (response) {
      // console.log(response);
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function loggedInUser(data) {
  return axios
    .post("user/loggedInUser", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function logOutUser() {
  return axios
    .get("user/logout")
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function changeUserStatus(items) {
  return axios
    .patch("user/", { userId: items.userId, status: items.status })
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function updateUser(data) {
  return axios
    .put("user/", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function getUser(data) {
  return axios
    .get(`user/${data.userId}`)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function changeUserPassword(items) {
  return axios
    .patch("user/changePassword", {
      oldPassword: items.oldPassword,
      newPassword: items.newPassword,
    })
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function sendVerificationCode(data) {
  return axios
    .post("user/forgotPassword", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function verifyCode(data) {
  return axios
    .post("user/verifyCode", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function updateUserPassword(items) {
  return axios
    .patch("user/updatePassword", {
      email: items.email,
      verificationCode: items.verificationCode,
      password: items.password,
    })
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

//-----------leaves api calls----------
export function fetchLeaves() {
  return axios
    .get("leaves/")
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function deleteLeave(id) {
  // console.log(id);
  return axios
    .delete("leaves/", { data: { leaveId: id } })
    .then(function (response) {
      // console.log(response);
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function addLeaves(data) {
  return axios
    .post("leaves/", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function updateLeave(data) {
  return axios
    .put("leaves/", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function getLeave(data) {
  // console.log(data);
  let url = `leaves/${data.leaveId}`;
  if (data.fromDate && data.toDate) {
    url = `leaves/${data.leaveId}?fromDate=${data.fromDate}&&toDate=${data.toDate}`;
  }
  return axios
    .get(url)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

//-----------------Holiday Apis-------
export function fetchHolidays() {
  return axios
    .get("holidays/")
    .then(function (response) {
      // console.log(response);
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function deleteHoliday(id) {
  // console.log(id);
  return axios
    .delete("holidays/", { data: { holidayId: id } })
    .then(function (response) {
      // console.log(response);
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function addHoliday(data) {
  return axios
    .post("holidays/", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function updateHoliday(data) {
  return axios
    .put("holidays/", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function getHoliday(data) {
  return axios
    .get(`holidays/${data.holidayId}`)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

//----------------salary schema-----------
export function updateSalarySettings(data) {
  return axios
    .put("salarySettings/", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function getSalarySettings(data) {
  return axios
    .get("salarySettings/")
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

//--------------salary list--------------

export function getApprovedSalaryList(data) {
  return axios
    .post("calculateSalary/user-leaves-list", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function getAvailableSalaryList(data) {
  return axios
    .post("calculateSalary/available-leave", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function addCalculatedSalary(data) {
  // console.log(data);
  return axios
    .post("calculateSalary", data)
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function getCalculatedSalary() {
  return axios
    .get("calculateSalary")
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function deleteCalculatedSalary(id) {
  return axios
    .delete("calculateSalary/", { data: { salaryId: id } })
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function generateCalculatedSalaryPDF(data) {
  return axios
    .get("calculateSalary/view-salary/" + data, { responseType: "blob" })
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

//-----------------------dashboard----------------------
export function UpcomingHolidays() {
  return axios
    .get("/dashboard/holidays")
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function UpcomingLeaves() {
  return axios
    .get("/dashboard/leaves")
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}

export function UpcomingEvents() {
  return axios
    .get("/dashboard/events")
    .then(function (response) {
      return { response };
    })
    .catch(function (error) {
      // console.log(error);
      return { error };
    });
}
