import { fork } from "redux-saga/effects";
import { authSaga } from "./authSaga";
import { userSaga } from "./userSaga";
import { leavesSaga } from "./leavesSaga";
import { holidaySaga } from "./holidaySaga";
import { salarySettingSaga } from "./salarySettingsSaga";
import { salaryListSaga } from "./salaryListSaga";

export default function* rootSaga() {
  yield fork(authSaga);
  yield fork(userSaga);
  yield fork(leavesSaga);
  yield fork(holidaySaga);
  yield fork(salarySettingSaga);
  yield fork(salaryListSaga);
}
